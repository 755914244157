
.App {
  /* text-align: center; */
  height: 100%;
}
.App .navbar {
  /* height: 79.6px; */
  background-color: #fca311;
}
.profileAccess button:focus,
.profileAccess button:active{
  -webkit-appearance:none!important;
}
.App header{
  height: 100%;
}
.App header .content{
  height: 100%;
}
.App .logo {
  /* animation: App-logo-spin infinite 20s linear;
  height: 40vmin; */
  height: 50px!important;
  width: 160px!important;
  pointer-events: none;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-note {
  color: #61dafb;
  background-color: blanchedalmond;
}

.pop-upbox {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Scanner-box {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #3795bd;
}

h4 {
  color: #000;
  /* text-align: center; */
  margin: 0px auto 16px;
}

.borderbtnhome {
  position: relative;
  border: 2px solid #2ea3f2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.8em !important;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  transition: all .2s;
  color: #2ea3f2 !important;
}

.none {
  display: none
}

svg {
  padding: 21px;
  background: #fff;
}

.data-container {
  margin: 25px 10px 0px 10px;
  padding: 25px;
  border: 1px solid white;
  color: white;
  width: 80%;
}

.detail {
  display: flex;
}

p.info {
  padding-left: 10px;
}

/**saurabh css**/
/* .navbar {
  padding-bottom: 15px;
  padding-top: 15px;
  background: #065ec9;
} */

.profileAccess {
  display: flex;
}

.profileIcon {
  cursor: pointer;

  margin-left: 30px;
  width: 36px;
}

.dropDown {
  position: relative;
}

.dropdown-profile {
  list-style: none;
  color: white;
  background-color: #007bffc4;
  position: absolute;
  padding-left: 0px;
  width: 230px;
  top: 58px;
  left: -53px;
  border: 1px solid #0175f3;
}

.dropdown-list1 {
  padding: 10px;
  border-bottom: 1px solid #ffff;
}

li:hover {
  /* background-color: #065ec9; */
  cursor: pointer;
}

.dropdown-list {
  padding: 5px;
}

a.nav-link {
  color: white !important;
}

.login_btn {
  background: white;
}

.cartIcon {
  margin-left: 30px;
  width: 36px;
}

.cartText {
  font-style: italic;
  font-weight: 600;
  font-family: sans-serif;
  font-size: 16px;
  color: white;
}

header .hero-section {
  /* background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vh - 75px) !important;
  height: 100vh; */
  /* background-image: linear-gradient(180deg,#ffffff 4%,rgba(255,255,255,0) 100%),url(back1.png)!important; */
  /* background-image: url(ecomm.jpg); */
}

.modal-content {
  margin-top: 70px;
  /* background: #065ec9; */
  background: #fff;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}

.p-btn {
  background: white;
  color: #065ec9;
  width: 110px;
}

.p-btn:hover {
  background: white;
  color: #065ec9;
}

.modal-footer {
  border-top: none;
  display: flex;
  justify-content: center;
}

img.spinner {
  width: 135px !important;
}

.welcomeLabel {
  color: white;
  margin-right: 10px;
  margin-bottom: 0px;
}

.btnView {
  margin-right: 10px;
}

.logo {
  filter: invert(100%);
  /* max-width: 50px; */
}

/* .logo-text {
  font-style: italic;
  font-family: sans-serif;
  font-size: 30px;
  color: white;
} */

.clickable {
  cursor: pointer;
}





/* New CSS - Vaibhav (Neobank) */


.navbar-nav .nav-item{
    
  font-weight: 400;
  font-size: 1rem;
}
.navbar-nav .nav-link{
  color: #000!important;
  margin-left: 15px;
}
.navbar-nav .nav-link:hover{
  border-bottom: 2px solid #000;
}
.profileAccess .btn{
  background-color: #fca311;
  color: #000!important;
  border: 3px solid #000;
  border-radius: 10px;
  margin: 5px 10px;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
}

.home1{
  background-color: #fca311;
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0%;
  padding-top: 110px;
  text-align: left;
  background-image: url("./girl4.png");
  background-size:  100% 100vh;
  background-repeat: no-repeat;

}
.home1 .container{
overflow: hidden;
height: 100%;
}
.home1 h3{
margin-top: 200px;
color: black;
font-weight: 700;
font-size: 2.5rem;
}
.home1 p{
font-size: 1.5rem;
font-weight: 200;
padding-bottom: 20px;
}
.left1{
  padding: 12px 25px;
  margin: 20px 0;
font-size: 1rem;
font-weight: 600;
color:#fff;
  background-color: #000;
text-align: center;
border-radius: 5px;
  text-decoration: none;
}
.left1:hover{
  color: #fff;
  text-decoration: none;
 
}



.home2{
  width: 100%;
  height: auto;
  margin: 50px 0;
}
.home2 h2{
  padding-bottom: 50px;
  color: #000;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
}
.extra-div{
  padding: 30px;
}
.extra-div h2{
  padding-bottom: 20px;
  color: #000;
  text-align: left;
  font-weight: 600;
  font-size: 2rem;
}
.extra-div h3{
  color: #343a40;
  font-size: 1.1rem;
  line-height: 30px;
}
.extra-div hr{
  width: 100px;
}
/* .extra-div a{
  padding: 12px 25px;
  margin-top: 20px;
font-size: 1rem;
font-weight: 600;
color:#000;
  background-color: #007f5f;
text-align: center;
border-radius: 5px;
  text-decoration: none;
}
.extra-div a:hover{
  color: #007f5f;
  background-color: #fff;
  border: 2px solid #007f5f;   
} */

.home3{
  height: auto;
  width: 100%;
  padding: 50px;
  background-color: #e9ecef;
}
.home3 img{
  height: auto;
  width: 100%;
  border-radius: 10px;
  padding: 50px 0;
}
.home3 .types{
  background-color: #ffcfd2;
  margin-top: 30px;
  padding: 50px;
}
.home3 .types1{
  background-color: #a2d2ff;
  margin-top: 30px;
  padding: 50px;
}
.home3 .types2{
  background-color: #95d5b2;
  margin-top: 30px;
  padding: 50px;
}
.home3 h2{
  font-size: 2.5rem;
  font-weight: 700;
  padding-bottom: 30px;
}
.home3 h3{
  font-weight: 600;
}

.footerr{
  height: auto;
  width: 100%;
  padding: 50px 0px;
  background: #000;
  
}
.footerr h2{
  color: #fff;
  text-align: left;
  font-size: 1.7rem;
  font-weight: 500;
  padding-top: 30px;
}
.social h2{
  font-weight: 600;
  padding: 30px;
}
.social a{
  font-size: 2rem;
  color: #000;
  background-color: none;
  padding: 5px;
  margin: 5px;
  transition: 0.3sec all;
}

/* .social a:hover{
  color: #000;
  background: #fff;
  border: none;
  transition: 0.3sec all;

} */


@media (max-width: 860px ){
  .home3{
      height: auto;
      background-size: contain;
  }
  .social a{
      margin-bottom: 30px ;
  }
}


